import React from 'react';
import Preistabelle from './PreistabelleComponent';
import Infos from './InfosComponent';

function PreiseUndInfo() {

  return (
    <article className="container box style3">
        <Preistabelle/>
        <Infos/>
    </article>
  );
}

export default PreiseUndInfo;