import React from 'react';

import translate from '../i18n/translate';

function Ausstattung(){
    
    return (
        <article className="container box style3">
            <header>
                <h2>{translate('furnishing')}</h2>
            </header>
            <p>{translate('furnishing-contains')}: </p>
            <ul className="template-ul">
                <li className="template-li">{translate('furnishing-contains-li-1')}</li>
                <li className="template-li">{translate('furnishing-contains-li-2')}</li>
                <li className="template-li">{translate('furnishing-contains-li-3')}</li>
                <li className="template-li">{translate('furnishing-contains-li-4')}</li>
                <li className="template-li">{translate('furnishing-contains-li-5')}</li>
            </ul>
            <p><b>{translate('furnishing-pets-not-allowed')}</b></p>
            <p><b>{translate('furnishing-parking')}</b></p>
        </article>
    );
}

export default Ausstattung;