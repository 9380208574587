import React from 'react';

function Feature({first, textRight, imageName, h2Text, descText}) {

  const image_ = require('../images/' + imageName);

  return (
    <article id={first ? "first" : ""} className={"container box style1 " + (textRight ? "right" : "left")}>
        <a href="#" className="image fit"><img src={image_} alt="" /></a>
        <div className="inner">
            <header>
                <h2>{h2Text}</h2>
            </header>
            <p>{descText}</p>
        </div>
    </article>
  );
  return null;
}

export default Feature;