import { LOCALES } from '../locales';

export default {
    [LOCALES.DUTCH]: {
        'apartment in kandersteg': 'Vakantiewoning in Kandersteg',
        'feature-1-desc': "Comfortabel appartement voor 4-6 personen. Grote tuin met gazon en picknicktafels en speeltoestellen voor kinderen (glijbaan, zandbak).",
        'furnishing': "Inrichting",
        'furnishing-contains': "De vakantiewoning op de eerste verdieping van Chalet Lindenheim omvat",
        'furnishing-contains-li-1': "Ruime woonkamer, met zithoek, bank, televisie en radio.",
        'furnishing-contains-li-2': "Grote keuken met eethoek, afwasmachine, nespressomachine, koffiezetapparaat en alles wat nodig is.",
        'furnishing-contains-li-3': "3 slaapkamers, waarvan 1 met balkon. In overleg is een vierde slaapkamer beschikbaar.",
        'furnishing-contains-li-4': "Badkamer met ligbad, wasbak, wc en grote kast.",
        'furnishing-contains-li-5': "De woning is het best geschikt voor 4 tot 6 Personen. Meer over minder in personen in overleg.",
        'furnishing-pets-not-allowed': "Huisdieren zijn alleen op afspraak en tegen betaling toegestaan.",
        'furnishing-parking': "Er zijn meerdere parkeerplaatsen voorhanden.",
        'banner-welcome': "Welkom"/* im Ferienappartement Lindenheim"*/,
        'banner-text-part-1': "U vindt ons 10 tot 15 minuten lopen vanaf het treinstation richting ",
        'banner-text-part-2': "300 meter na ",
        'banner-text-part-3': ", links op een kleine heuvel. ",
        'banner-text-part-4': "In de winter is het appartement direct naast de langlaufloipe, inclusief verlichte nachtloipe.",
        'banner-button-text': "Overzicht",
        'booking-calendar-info': "Kies een begin- en einddatum.",
        'contact-header-text': "Contact",
        'contact-desc': "Stuur ons direct een bericht via het formulier (zie hieronder) of neem contact met ons op via e-mail of telefoon. Als u vragen heeft, informeren wij u graag verder.",
        'contact-td-left-address': "Adres",
        'phone': "Telefoon",
        'mobile': "Mobiel",
        'booking': "Email sturen",
        'image-gallery': "Foto galerij",
        'header-holiday-apartment-lindenheim': "Vakantiewoning Lindenheim",
        'header-button-welcome-text': "Boeking",
        'infos-heading': "Informatie",
        'infos-li-1': "De prijzen zijn inclusief beddengoed.",
        'infos-li-2': "De toeristenbelasting is voor volwassenen 4.60 CHF per dag en voor kinderen van 6 tot 16 jaar CHF 2.50 per dag en worden apart afgerekend, net als de schoonmaakkosten van 80-120 CHF.",
        'infos-li-3': "In het hoofdseizoen is de woning bij voorkeur per week te huur, van zaterdag tot zaterdag, in het tussenseizoen kan de woning vanaf 3 dagen gehuurd worden. U kunt met ons contact opnemen, om de beschikbaarheid en duur van uw verblijf te bespreken.",
        'infos-li-4': "Als het appartement wordt bewoond door minder dan 4-6 personen. of minder dan 1 week (alleen laagseizoen), een huurprijs is van toepassing op afspraak.",
        'infos-li-5': "Aankomst vanaf 15.00 uur, of vroeger, als de woning voor u niet bezet was.",
        'infos-li-6': "Vertrek om 10.30, of later, als de woning na u niet bezet is.",
        'infos-li-7': "Medegebruik van de gedeelde wasmachine voor CHF 2 per wascyclus",
        'message-was-sent': "Bericht is verzonden!",
        'error-sending': "Verzenden is mislukt: ",
        'please-give-name': "Voer uw naam in.",
        'please-give-email': "Vul alstublieft uw e-mailadres in.",
        'please-select-timespan': "Selecteer een periode",
        'pricetable-heading': "Prijstabel",
        'pricetable-th-1': "Periode",
        'pricetable-th-2': "4 personen",
        'pricetable-th-3': "6 personen",
        'ppue': "Prijzen per nacht in CHF",
        'wpaa': "Verdere prijzen op aanvraag",
        'christmas-newyear': "Kerstmis - Nieuwjaar",
        'january': "Januari",
        'february-march': "Februari - Maart",
        'april-june': "April - Juni",
        'july-september': "Juli - September",
        'july-august': "Juli - August",
        'september': "September",
        'october-december': "October - December",
        'german': "Duitse",
        'english': "Engels",
        'dutch': "Nederlands",
    }
}