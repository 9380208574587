import React, {useState} from 'react';
import Select from 'react-select';
import Home from './components/HomeComponent';

import './style/main/css/main_compiled_fixed.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './style/react-dates_overrides.css';

import { LOCALES, I18nProvider } from './i18n';
import translate from './i18n/translate';


function App() {
  const [locale, setLocale] = useState(LOCALES.GERMAN);

  const options = [
    { value: LOCALES.GERMAN, label: translate('german') },
    { value: LOCALES.ENGLISH, label: translate('english') },
    { value: LOCALES.DUTCH, label: translate('dutch') },
  ];
  const selectDivStyles = {
    position: 'fixed!important',
    top: '0px!important',
    right: '0px!important',
    width: '164px',
    
  };
  const customSelectStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: '164px',
      position: 'absolute',
      zIndex: 999,
      color: state.selectProps.menuColor,
      padding: 10,
      margin: 10,
    }),
    control: (_, { selectProps: { width }}) => ({
      width: width,
      zIndex: 999,
      position: 'absolute',
      height: '128px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition, margin: 0, width: '164px', padding: '20px', backgroundColor: '#35b88f', color: 'white', borderRadius: 6, textAlign: 'center', fontSize: '32px', fontWeight: 'bold'};
    }
  }

  return (
        <I18nProvider locale={locale}>
          <div style={selectDivStyles}>
            <Select options={options} defaultValue={ options[0] } onChange={(e) => setLocale(e.value)} width='400px' styles={customSelectStyles} menuColor='blue'/>
          </div>
          <Home/>
        </I18nProvider>
  );
}

export default App;