import { LOCALES } from '../locales';

export default {
    [LOCALES.GERMAN]: {
        'apartment in kandersteg': 'Ferienwohnung in Kandersteg',
        'feature-1-desc': "Komfortable Wohnung für 4-6 Personen. Grosser Hof mit Liegewiese und Picknicktische sowie Spielgeräte für Kinder (Rutsche, Sandkasten).",
        'furnishing': "Ausstattung",
        'furnishing-contains': "Die Ferienwohnung im 1. Stock des Chalets Lindenheim umfasst",
        'furnishing-contains-li-1': "Geräumiges Wohnzimmer mit Eckbank, Couch und Satellitenfenster.",
        'furnishing-contains-li-2': "Grosse Küche mit Essecke, Geschirrspüler, Filterkaffeemaschine und Nespressomaschine (Kapseln selber mitbringen) und alles, was man braucht.",
        'furnishing-contains-li-3': "3 Doppelschlafzimmer (wovon eines mit Südbalkon), nach Absprache ist ein viertes Schlafzimmer möglich.",
        'furnishing-contains-li-4': "Badezimmer mit Badewanne, Lavabo und WC.",
        'furnishing-contains-li-5': "Die Wohnung ist sehr komfortabel für 4-6 Personen.",
        'furnishing-pets-not-allowed': "Haustiere sind nur nach Absprache und kostenpflichtig erlaubt.",
        'furnishing-parking': "Es sind mehrere Parkplätze vorhanden.",
        'banner-welcome': "Willkommen"/* im Ferienappartement Lindenheim"*/,
        'banner-text-part-1': "Sie finden uns 10 - 15 Minuten zu Fuss vom Bahnhof Richtung ",
        'banner-text-part-2': "300m nach ",
        'banner-text-part-3': ", links neben der Dorfstrasse auf kleiner Anhöhe. ",
        'banner-text-part-4': "Im Winter liegt die Wohnung direkt neben der Langlaufskiloipe inkl. Nachtloipe.",
        'banner-button-text': "Übersicht",
        'booking-calendar-info': "Wähle ein Anfangs- und End-Datum (in der Hauptsaison mindestens 7 Tage).",
        'contact-header-text': "Kontakt",
        'contact-desc': "Senden Sie uns eine Nachricht direkt mit dem Formular (siehe unten) oder wenden Sie sich per E-Mail oder Telefon an uns. Bei Fragen geben wir gerne Auskunft.",
        'contact-td-left-address': "Adresse",
        'phone': "Telefon",
        'mobile': "Mobil",
        'booking': "Nachricht senden",
        'image-gallery': "Bilder-Galerie",
        'header-holiday-apartment-lindenheim': "Ferienwohnung Lindenheim",
        'header-button-welcome-text': "Buchen",
        'infos-heading': "Informationen",
        'infos-li-1': "Preise inkl. Bett- und Küchenwäsche, Handtücher.",
        'infos-li-2': "Preise exkl. Kurtaxen (Erw. pro Tag CHF 4.60, Kinder 6 - 16 Jahre pro Tag CHF 2.50) und Endreinigung (CHF 80-120).",
        'infos-li-3': "Mindestdauer in der Hauptsaison: 1 Woche.",
        'infos-li-4': "Bei Belegung der Wohnung von weniger als 4-6 Personen oder kürzer als 1 Woche (nur Zwischensaison) gilt ein Mietpreis nach Vereinbarung.",
        'infos-li-5': "Anreise jeweils am Samstag ab 16:00 Uhr (je nachdem ob die Wohnung vorher belegt war).",
        'infos-li-6': "Abreise jeweils am Samstag ca. 10:30 Uhr (je nachdem ob die Wohnung nachher belegt ist).",
        'infos-li-7': "Mitbenutzen der gemeinsamen Waschmaschine für 2 Fr. pro Waschgang",
        'message-was-sent': "Nachricht wurde gesendet!",
        'error-sending': "Fehler beim Senden: ",
        'please-give-name': "Bitte geben Sie Ihren Namen an.",
        'please-give-email': "Bitte geben Sie Ihre E-Mail an.",
        'please-select-timespan': "Bitte wählen Sie einen Zeitraum aus",
        'pricetable-heading': "Preistabelle",
        'pricetable-th-1': "Periode",
        'pricetable-th-2': "4 Personen",
        'pricetable-th-3': "6 Personen",
        'ppue': "Preise pro Übernachtung in CHF",
        'wpaa': "Weitere Preise auf Anfrage",
        'christmas-newyear': "Weihnachten - Neujahr",
        'january': "Januar",
        'february-march': "Februar - März",
        'april-june': "April - Juni",
        'july-september': "Juli - September",
        'july-august': "Juli - August",
        'september': "September",
        'october-december': "Oktober - Dezember",
        'german': "Deutsch",
        'english': "Englisch",
        'dutch': "Holländisch",
    }
}