import React from 'react';

function FerienhausmieteCalendar(){

    return (
        <article className="container box style2">
            <div id="ferienhausmiete-de-widget4-11926"><a href="https://www.ferienhausmiete.de/11926.htm" target="_blank">Lindenheim</a></div>
        </article>
    );
}

export default FerienhausmieteCalendar;