import React from 'react';

function Footer(){
    
    return (
        <section id="footer">
			<div className="copyright">
				<ul className="menu template-ul">
					<li className="template-li">Copyright &copy; Familie Konrad und Sandy Hodel - La Grouw 2020.</li>
				</ul>
			</div>
		</section>
    );
}

export default Footer;