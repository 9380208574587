import React from 'react';
import translate from '../i18n/translate';

function Gallery(){
    
    return (
        <article className="container box style2">
            <header>
                <h2>{translate('image-gallery')}</h2>
            </header>
            <div className="inner gallery">
                <div className="row gtr-0">
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-09.JPG")} className="image fit"><img src={require("../images/x-09.JPG")} alt=""/></a></div>
                    
                    {/* <div className="col-3 col-12-mobile"><a href={require("../images/x-05.jpeg")} className="image fit"><img src={require("../images/x-05.jpeg")} alt=""/></a></div> */}
                    <div className="col-3 col-12-mobile"><a href={require("../images/2022_Feb_04.jpeg")} className="image fit"><img src={require("../images/2022_Feb_04.jpeg")} alt=""/></a></div>
                    
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-04.jpeg")} className="image fit"><img src={require("../images/x-04.jpeg")} alt=""/></a></div>

                    <div className="col-3 col-12-mobile"><a href={require("../images/2022_Feb_05.jpeg")} className="image fit"><img src={require("../images/2022_Feb_05.jpeg")} alt=""/></a></div>
                </div>
                <div className="row gtr-0">
                    {/* <div className="col-3 col-12-mobile"><a href={require("../images/x-03.jpeg")} className="image fit"><img src={require("../images/x-03.jpeg")} alt=""/></a></div> */}
                    <div className="col-3 col-12-mobile"><a href={require("../images/2022_Feb_03.jpeg")} className="image fit"><img src={require("../images/2022_Feb_03.jpeg")} alt=""/></a></div>

                    {/* <div className="col-3 col-12-mobile"><a href={require("../images/2022_Feb_02_Cropped.png")} className="image fit"><img src={require("../images/2022_Feb_02_Cropped.png")} alt=""/></a></div> */}
                    {/* <div className="col-3 col-12-mobile"><a href={require("../images/x-02.jpeg")} className="image fit"><img src={require("../images/x-02.jpeg")} alt=""/></a></div> */}
                    <div className="col-3 col-12-mobile"><a href={require("../images/2022_Feb_01.jpeg")} className="image fit"><img src={require("../images/2022_Feb_01.jpeg")} alt=""/></a></div>
                    
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-06.jpeg")} className="image fit"><img src={require("../images/x-06.jpeg")} alt=""/></a></div>
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-01.jpeg")} className="image fit"><img src={require("../images/x-01.jpeg")} alt=""/></a></div>
                    
                </div>
                <div className="row gtr-0">
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-07.jpeg")} className="image fit"><img src={require("../images/x-07.jpeg")} alt=""/></a></div>

                    <div className="col-3 col-12-mobile"><a href={require("../images/x-08.jpeg")} className="image fit"><img src={require("../images/x-08.jpeg")} alt=""/></a></div>
                    
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-10.jpeg")} className="image fit"><img src={require("../images/x-10.jpeg")} alt=""/></a></div>
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-00.jpeg")} className="image fit"><img src={require("../images/x-00.jpeg")} alt=""/></a></div>
                </div>
                <div className="row gtr-0">
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-12.jpg")} className="image fit"><img src={require("../images/x-12.jpg")} alt=""/></a></div>
                    {/* Removed Feb 2022 <div className="col-3 col-12-mobile"><a href={require("../images/x-11.jpg")} className="image fit"><img src={require("../images/x-11.jpg")} alt=""/></a></div> */}
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-13.jpg")} className="image fit"><img src={require("../images/x-13.jpg")} alt=""/></a></div>
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-14.jpg")} className="image fit"><img src={require("../images/x-14.jpg")} alt=""/></a></div>
                    <div className="col-3 col-12-mobile"><a href={require("../images/x-15.JPG")} className="image fit"><img src={require("../images/x-15.JPG")} alt=""/></a></div>
                </div>

                <div className="">
                    {/*<div className="col-3 col-12-mobile"><a href={require("../images/gallery01.png")} className="image fit"><img src={require("../images/gallery01.png")} alt=""/></a></div>*/}
                    {/*<div className="col-3 col-12-mobile"><a href={require("../images/gallery02.png")} className="image fit"><img src={require("../images/gallery02.png")} alt=""/></a></div>*/}
                    {/*<div className="col-3 col-12-mobile"><a href={require("../images/gallery03.png")} className="image fit"><img src={require("../images/gallery03.png")} alt=""/></a></div>*/}
                    {/*<div className="col-3 col-12-mobile"><a href={require("../images/gallery04.png")} className="image fit"><img src={require("../images/gallery04.png")} alt=""/></a></div>*/}
                    {/*<div className="col-3 col-12-mobile"><a href={require("../images/gallery05.png")} className="image fit"><img src={require("../images/gallery05.png")} alt="" title="Cherish" /></a></div>*/}
                    {/*<div className="col-3 col-12-mobile"><a href={require("../images/gallery06.png")} className="image fit"><img src={require("../images/gallery06.png")} alt="" title="Different." /></a></div>*/}
                    {/*<div className="col-3 col-12-mobile"><a href={require("../images/gallery07.png")} className="image fit"><img src={require("../images/gallery07.png")} alt="" title="History was made here" /></a></div>*/}
                    {/*<div className="col-3 col-12-mobile"><a href={require("../images/gallery08.png")} className="image fit"><img src={require("../images/gallery08.png")} alt="" title="People come and go and walk away" /></a></div>*/}
                </div>
            </div>
        </article>
    );
}

export default Gallery;