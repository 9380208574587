import { LOCALES } from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'apartment in kandersteg': 'Holiday-Apartment in Kandersteg',
        'feature-1-desc': "Comfortable apartment for 4-6 people. Large courtyard with lawn and picnic tables as well as play equipment for children (slide, sandpit).",
        'furnishing': "Furnishing",
        'furnishing-contains': "The apartment on the first floor of chalet Lindenheim contains",
        'furnishing-contains-li-1': "Large living room with satellite TV and Radio/CD-Player.",
        'furnishing-contains-li-2': "Large kitchen with dishwasher, coffee maker and all one needs (no microwave).",
        'furnishing-contains-li-3': "Up to 4 bedrooms with each two beds (one bedroom with balcony).",
        'furnishing-contains-li-4': "Bathroom with bathtub, WC, sink, large closet.",
        'furnishing-contains-li-5': "The apartment is very comfortable for 4-6 persons.",
        'furnishing-pets-not-allowed': "Pets are only allowed by arrangement and for a fee.",
        'furnishing-parking': "Multiple parking spaces are available.",
        'banner-welcome': "Welcome"/* to Holiday Apartment Lindenheim"*/,
        'banner-text-part-1': "You can reach us in a 10 to 15 minutes walk from the train station in the direction of ",
        'banner-text-part-2': "300m after ",
        'banner-text-part-3': ", to the left of the village street on a small hill. ",
        'banner-text-part-4': "In winter the apartment is right next to the cross-country ski run.",
        'banner-button-text': "Overview",
        'booking-calendar-info': "Select a start- and end-date (at least 7 days during main season).",
        'contact-header-text': "Contact",
        'contact-desc': "Send us a message directly using the form (see below) or contact us by email or phone. We are happy to provide information if you have any questions.",
        'contact-td-left-address': "Address",
        'phone': "Phone",
        'mobile': "Mobile",
        'booking': "Send a message",
        'image-gallery': "Image-Gallery",
        'header-holiday-apartment-lindenheim': "Holiday Apartment Lindenheim",
        'header-button-welcome-text': "Booking",
        'infos-heading': "Informations",
        'infos-li-1': "The prices include bed laundry, kitchen towels.",
        'infos-li-2': "Tourism taxes (CHF 4.60 per day for adults, CHF 2.50 per day for children 6-16 years) and final cleaning (CHF 80-120) are not included.",
        'infos-li-3': "During high season at least one week.",
        'infos-li-4': "Prices can vary in low season, based on the number of occupants, or the number of days.",
        'infos-li-5': "Arrival: Saturdays at 16.00, depending on if the apartment was occupied before.",
        'infos-li-6': "Departure: Saturdays at 10.30, depending on if the apartment is occupied afterwards.",
        'infos-li-7': "Use of the joint washing machine for Fr. 2 per load",
        'message-was-sent': "Message was sent!",
        'error-sending': "Error while sending message: ",
        'please-give-name': "Please provide your name.",
        'please-give-email': "Please provide your email.",
        'please-select-timespan': "Please select a time span.",
        'pricetable-heading': "Pricetable",
        'pricetable-th-1': "Period",
        'pricetable-th-3': "6 People",
        'ppue': "Prices per Night in CHF",
        'wpaa': "More prices on request",
        'pricetable-th-2': "4 People",
        'christmas-newyear': "Christmas Holidays",
        'january': "January",
        'february-march': "February - March",
        'april-june': "April - June",
        'july-september': "July - September",
        'july-august': "July - August",
        'september': "September",
        'october-december': "October - December",
        'german': "German",
        'english': "English",
        'dutch': 'Dutch',
    }
}