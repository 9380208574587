
import React from 'react';
import translate from '../i18n/translate';

function Preistabelle(){
    return (
        <section>
            <header>
                <h2>{translate('pricetable-heading')}</h2>
            </header>
            <div className="table-wrapper">
                <table className="template-table">
                    <thead className="template-thead">
                        <tr className="template-tr">
                            <th className="template-th">{translate('pricetable-th-1')}</th>
                            <th className="template-th">{translate('pricetable-th-2')}</th>
                            <th className="template-th">{translate('pricetable-th-3')}</th>
                        </tr>
                    </thead>
                    <tbody className="template-tbody">
                        <tr className="template-tr">
                            <td className="template-td">{translate('christmas-newyear')}</td>
                            <td className="template-td">160.-</td>
                            <td className="template-td">160.-</td>
                        </tr>
                        <tr className="template-tr">
                            <td className="template-td">{translate('january')}</td>
                            <td className="template-td">110.-</td>
                            <td className="template-td">130.-</td>
                        </tr>
                        <tr className="template-tr">
                            <td className="template-td">{translate('february-march')}</td>
                            <td className="template-td">130.-</td>
                            <td className="template-td">150.-</td>
                        </tr>
                        <tr className="template-tr">
                            <td className="template-td">{translate('april-june')}</td>
                            <td className="template-td">110.-</td>
                            <td className="template-td">130.-</td>
                        </tr>
                        {/*<tr className="template-tr">
                            <td className="template-td">{translate('july-september')}</td>
                            <td className="template-td">125.-</td>
                            <td className="template-td">145.-</td>
    </tr                >*/}
                        <tr className="template-tr">
                            <td className="template-td">{translate('july-september')}</td>
                            <td className="template-td">130.-</td>
                            <td className="template-td">150.-</td>
                        </tr>
                        {/*<tr className='template-tr'>
                            <td className='template-td'>{translate('september')}</td>
                            <td className='template-td'>125.-</td>
                            <td className='template-td'>145.-</td>
</tr                    >*/}
                        <tr className="template-tr">
                            <td className="template-td">{translate('october-december')}</td>
                            <td className="template-td">110.-</td>
                            <td className="template-td">130.-</td>
                        </tr>
                    </tbody>
                    <tfoot className="template-tfoot">
                    </tfoot>
                </table>
                <p>*{translate('ppue')}</p>
                <p>*{translate('wpaa')}</p>
            </div>
        </section>
    );
}

export default Preistabelle;