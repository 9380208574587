import React from 'react';

import translate from '../i18n/translate';

function Banner() {

  const gemmi = "https://www.tripadvisor.com/Attraction_Review-g198863-d1205107-Reviews-Gemmi_Pass-Kandersteg_Canton_of_Bern.html";
  const des_alpes = "https://desalpes-kandersteg.ch/web/";

  return (
    <section id="banner">
        <header>
            <h2>{translate('banner-welcome')}</h2>
        </header>
        <p>
          {translate('banner-text-part-1')}
          <a href={gemmi} target="_blank" rel="noopener noreferrer">Gemmi</a>,<br />
          {translate('banner-text-part-2')}<a href={des_alpes} target="_blank" rel="noopener noreferrer">Hotel Des Alpes</a>
          {translate('banner-text-part-3')}<br />
          {translate('banner-text-part-4')}</p>
        <footer>
            <a href="#first" className="button style2 scrolly">{translate('banner-button-text')}</a>
        </footer>
    </section>
  );
}

export default Banner;