import React from 'react';
import translate from '../i18n/translate';

function Infos(){
    return (
        <section>
            <header>
                <h2>{translate('infos-heading')}</h2>
            </header>
            <ul className="template-ul">
                <li className="template-li">{translate('infos-li-1')}</li>
                <li className="template-li">{translate('infos-li-2')}</li>
                <li className="template-li">{translate('infos-li-3')}</li>
                <li className="template-li">{translate('infos-li-4')}</li>
                <li className="template-li">{translate('infos-li-5')}</li>
                <li className="template-li">{translate('infos-li-6')}</li>
                <li className="template-li">{translate('infos-li-7')}</li>
            </ul>
        </section>
    );
}

export default Infos;