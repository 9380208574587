import React from 'react';
import MailForm from './MailFormComponent';
import translate from '../i18n/translate';

function Contact(){
    
    return (
        <article className="container box style3" id="buchen">
            <header>
                <h2>{translate('contact-header-text')}</h2>
            </header>
            <p>{translate('contact-desc')}</p>
            <hr className="template-hr"/>
            <table className="template-table">
                <tbody className="template-tbody">
                    <tr className="template-tr">
                        <td className="template-td">{translate('contact-td-left-address')}</td>
                        <td className="template-td">Familie Konrad und Sandy Hodel - La Grouw<br/>Sagigässli 25<br/>3718 Kandersteg</td>
                    </tr>
                    <tr className="template-tr">
                        <td className="template-td">{translate('phone')}/Fax</td>
                        <td className="template-td">033 675 23 16<br/>0041 33 675 23 16 (international)</td>
                    </tr>
                    <tr className="template-tr">
                        <td className="template-td">{translate('mobile')}</td>
                        <td className="template-td">079 631 58 57<br/>0041 79 631 58 57 (international)</td>
                    </tr>
                </tbody>
                <tfoot className="template-tfoot">
                    <tr className="template-tr">
                        <td className="template-td" colSpan="2"></td>
                    </tr>
                </tfoot>
            </table>
            <hr className="template-hr"/>
            <header>
                <h2>{translate('booking')}</h2>
            </header>
            <MailForm/>
        </article>
    );
}

export default Contact;