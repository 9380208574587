import React from 'react';

function Lage(){

    const iframestyle = {
        border: 0,
        borderRadius: 15 + 'px'
    };

    return (
        <article className="container box style2">
            <iframe title="fewolindenheimmapsframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d843.2403514363398!2d7.6707116!3d46.4880319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478f059db54ab2e1%3A0xd051ac4ecce364c0!2sFerienwohnung%20Lindenheim!5e1!3m2!1sde!2sch!4v1590523570676!5m2!1sde!2sch" width="100%" height="550" frameborder="0" style={iframestyle} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </article>
    );
}

export default Lage;