import React from 'react';
import Header from './HeaderComponent';
import Banner from './BannerComponent';
import Feature from './FeatureComponent';
import Gallery from './GalleryComponent';
import Contact from './ContactComponent';
import Footer from './FooterComponent';
import Ausstattung from './AusstattungComponent';
import PreiseUndInfo from './PreiseUndInfoComponent';
import Lage from './LageComponent';
import FerienhausmieteCalendar from './FerienhausmieteCalendar';

import translate from '../i18n/translate';

function Home_() {

    return (
        <div>
            <Header/>
            <Banner/>
            <Feature
                first={true}
                textRight={false}
                imageName="appartment01.png"
                h2Text={translate('apartment in kandersteg')}
                descText={translate('feature-1-desc')}
            />
            <Gallery/>
            <Ausstattung/>
            <FerienhausmieteCalendar/>
            <PreiseUndInfo/>
            <Lage/>
            <Contact/>
            <Footer/>
        </div>
    );

}

export default Home_;