import React, {useState} from 'react';
import emailjs from 'emailjs-com';
// import BookingCalendar from './BookingCalendarComponent';
// import firebase from '../firebase';
import translate from '../i18n/translate';

function MailForm() {

    /* ALERTS */

    const [alerts, setAlerts] = useState([]);

    const onRemoveAlert = i => {
        setAlerts(alerts => {
            return alerts.filter((val, j) => i !== j);
        });
    }

    function createAlert(bIsGood, text){
        const alertStyle={
            padding: 20 + 'px',
            backgroundColor: bIsGood ? 'green' : 'red',
            color: 'white',
            marginTop: 10 + 'px',
            marginBottom: 10 + 'px'
        };
        const closebtnStyle={
            marginLeft: 15 + 'px',
            color: 'white',
            fontWeight: 'bold',
            float: 'right',
            fontSize: 32 + 'px',
            cursor: 'pointer',
            transition: 0.3 + 's',
            verticalAlign: 'middle',
        };
        const alert = {
            text: text,
            alertStyle: alertStyle,
            closebtnStyle: closebtnStyle
        }
        setAlerts(alerts => [...alerts, alert]);
    }

    /* END ALERTS */


    /* EMAIL */

    function sendEmail(e) {

        e.preventDefault();
    
        emailjs.sendForm('default_service', 'template_GoK7hfuR', e.target, 'user_nynA21IUTRmlfkuPlNUP3')
          .then((result) => {
              createAlert(true, translate('message-was-sent'));
              console.log(result.text);
          }, (error) => {
              createAlert(false, (<>{translate('error-sending')}{error.text}</>));
              console.log(error.text);
          });
    }

    /* END EMAIL */

    /*
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);*/
    const [name, setName] = useState('');
    const [email___, setEmail___] = useState('');
    const [message, setMessage] = useState('');

    /*
    function onBookingCalendarChanged(calProps) {
        setStartDate(calProps.startDate);
        setEndDate(calProps.endDate);
    }
    */

    function addBookingDocument(startDate_, endDate_, email_, name_) {
        /*
        const db = firebase.firestore();
        const bookings = db.collection("bookings");

        // workaround
        let email__ = "".concat(email_);
        let name__ = "".concat(name_);

        bookings.add({
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            confirmed: false,
            email: email__,
            name: name__,
          }).then((docRef) => {
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
          */
         return null;
    }

    function submit(e, name_, email_, message_/*, startDate_, endDate_*/) {
        e.preventDefault();
        if (name_ === "" || name_ === null) {
            createAlert(false, translate('please-give-name'));
            return null;
        }
        if (email_ === "" || email_ === null) {
            createAlert(false, translate('please-give-email'));
            return null;
        }
        /*
        if (startDate_ === null || endDate_ === null) {
            createAlert (false, translate('please-select-timespan'));
            return;
        }*/
        sendEmail(e);
        // addBookingDocument(startDate_, endDate_, email_, name_);
        setName('');
        setEmail___('');
        setMessage('');
        return null;
    }

    return (
        
        <div>
            <form onSubmit={e => submit(e, name, email___, message/*, startDate, endDate*/)}>
                <div className="row gtr-50">
                    <div className="col-6 col-12-mobile">
                        <input type="text" className="text" id="inputvalbox1" name="name" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
                    </div>
                    <div className="col-6 col-12-mobile">
                        <input type="text" className="text" id="inputvalbox2" name="email" placeholder="Email" value={email___} onChange={e => setEmail___(e.target.value)}/>
                    </div>

                    {/*<BookingCalendar onChange={onBookingCalendarChanged}/>
                    <input type="hidden" name="start-date" value={startDate != null ? startDate.format("DD.MM.YYYY") : ""}></input>
                    <input type="hidden" name="end-date" value={endDate != null ? endDate.format("DD.MM.YYYY") : ""}></input>*/}

                    <div className="col-12">
                        <textarea id="inputvalbox3" name="message" placeholder="Message" value={message} onChange={e => setMessage(e.value)}></textarea>
                    </div>
                    <div className="col-12">
                        <ul className="actions template-ul">
                            <li className="template-li"><input type="submit" value="Send" /></li>
                        </ul>
                    </div>
                </div>
            </form>
            <div id="alert-container">
                {alerts.map((alert, i) => (
                    <div style={alert.alertStyle}>
                        <span className="template-span" style={alert.closebtnStyle} onClick={() => onRemoveAlert(i)}>&times;</span>
                        <p><strong>{alert.text}</strong></p>
                    </div>
                ))}
            </div>
        </div>
    );
    return null;
}

export default MailForm;