import React from 'react';
import translate from '../i18n/translate';

function Header() {
  return (
    /*<section id="header" style={{backgroundImage: `url("kandersteg_area_summer.jpeg")`, backgroundSize: 'cover'}}>*/
    <section id="header" style={{backgroundImage: `url("banner-img-2.jpeg")`, backgroundSize: 'cover'}}>
        <header>
            <h1>{translate('header-holiday-apartment-lindenheim')}</h1>
            <p>Kandersteg, Berner Oberland</p>
        </header>
        <footer>
            <a href="#buchen" className="button style2 scrolly-middle">{translate('header-button-welcome-text')}</a>
        </footer>
    </section>
  );
}

export default Header;